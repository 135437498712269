import Layout from "components/layout";
import CvTranslationServices from "components/CvTranslationServices";
import React from "react";
import SEO from "components/seo";

function index() {
  const title =
    "CV translation services";
  const description =
    "Tomedes' professional translators provide accurate CV translation services in over 100 languages. We use native speakers to deliver high quality, 24/7 translation services to tight deadlines.";
  const keywords = "Cv translation, resume translation services, certified translation services";
  const slug = "/cv-and-resume-translation-services.php";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug={slug}
      />
      <CvTranslationServices />
    </Layout>
  );
}

export default index;
