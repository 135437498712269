import React from "react";
import { Link } from "gatsby";
import OptimizedImage from "hooks/OptimizedImage";

function BannerCvTranslationServices() {
  return (
    <div className="bannerImage recent-bg cv-resume bg-no-repeat bg-cover bg-center text-white pb-28 pt-16">
      <div className=" mx-auto  pb-8 sm:w-[90%] w-[80%] pt-20 lg:max-w-7xl">
        <h1 className="text-[22px] sm:text-[40px] md:text-[48px] lg:text-60 bg-contact-img custom-bg-pos bg-no-repeat leading-[40px] lg:leading-[60px] inline-block font-medium w-auto lg:w-max font-riot custom-bg-h1 mb-4">
          CV translation services
        </h1>
        <br />
        <div className="bg-black bg-opacity-50 bg-no-repeat px-4 font-primary text-[14px] sm:text-2xl mt-7 inline-flex flex-col lg:flex-row gap-4 lg:gap-5 lg:items-center lg:mt-7">
          <div className="flex items-center">
            <OptimizedImage
              src="https://tomedes.gumlet.io/frontend/images/banner/check_sign.svg"
              alt=""
              className="w-6 h-6 mr-4"
            />
            <span className="">24/7 Human Support</span>
          </div>
          <div className="flex items-center">
            <OptimizedImage
              src="https://tomedes.gumlet.io/frontend/images/banner/check_sign.svg"
              alt=""
              className="w-6 h-6 mr-4"
            />
            <span className="">1 Year Guarantee</span>
          </div>
          <div className="flex items-center">
            <OptimizedImage
              src="https://tomedes.gumlet.io/frontend/images/banner/check_sign.svg"
              alt=""
              className="w-6 h-6 mr-4"
            />
            <span className="content-left">95,000 Business Customers</span>
          </div>
        </div>
        <div className="grid grid-cols-1 py-6 space-y-6 text-center md:flex md:flex-row md:align-baseline md:items-center md:space-x-6 md:space-y-0 font-opensans mt-7">
          <Link
            to="/quote"
            className="fr-btn1 tomedes-button green-btn-hv text-new uppercase rounded-full py-4 bg-orange text-white cursor-pointer w-60  font-semibold font-primary hover:bg-[#ff9a16] transition-colors duration-300"
          >
            Get Started
          </Link>
          <Link
            to="/contactus.php"
            className="fr-btn2 tomedes-button uppercase rounded-full bg-white py-4 text-black text-sm  cursor-pointer w-60 font-semibold font-primary text-new "
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BannerCvTranslationServices;
