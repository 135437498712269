import React from "react";

function SecondCvTranslationServices() {
  return (
    <>
      <div className="bg-darkBlue">
        <div className="w-11/12 sm:w-10/12 lg:w-8/12 max-w-6xl mx-auto py-28 text-24 leading-7 font-sans text-white text-center">
          <p className="mb-5">
            If you intend to migrate to another country you might need to have
            the required documents translated into that country’s language. At
            Tomedes, we have been working at translating such documents like
            CVs, Statement of Purpose and Cover letters among others, in a wide
            variety of languages.
          </p>
          <p>
            It has been our experience that literal translations are not
            effective enough; in fact sometimes they can turn out to be
            detrimental when the information that is intended to be conveyed is
            distorted or lost in the process of translation.
          </p>
        </div>
      </div>
      <div className="bg-darkBlue">
        <div className="w-full sm:w-10/12 lg:w-9/12 max-w-6xl mx-auto pt-16 pb-16 text-white font-sans">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            <div className="bg-black rounded-lg shadow-lg relative px-7 pb-5">
              <img
                src="https://skins.tomedes.com/frontend/images/cv-images/quality-guaranteed-color.svg"
                alt=""
                className="w-24 h-24 absolute top-0 left-[50%] translate-x-[-50%]"
              />
              <h2 className="text-[30px] text-center pt-24 mb-8">
                High Quality
              </h2>
              <p className="text-[22px] font-light">
                Our high quality CV translation service provides you with the
                perfect resume, no matter which country you want to work in.
                Impress potential employers from the outset with your
                word-perfect CV thanks to our top quality translation service.
              </p>
            </div>
            <div className="bg-black rounded-lg shadow-lg relative px-7 pb-5">
              <img
                src="https://skins.tomedes.com/frontend/images/cv-images/many-translators-color.svg"
                alt=""
                className="w-24 h-24 absolute top-0 left-[50%] translate-x-[-50%]"
              />
              <h2 className="text-[30px] text-center pt-24 mb-8">
                Native Translators
              </h2>
              <p className="text-[22px] font-light">
                We use native speakers of the CV's target language in order to
                provide the most accurate translation possible. Our native
                translators have the language skills and knowledge needed to
                make your CV shine.
              </p>
            </div>
            <div className="bg-black rounded-lg shadow-lg relative px-7 pb-5">
              <img
                src="https://skins.tomedes.com/frontend/images/cv-images/fast-delivery-color.svg"
                alt=""
                className="w-24 h-24 absolute top-0 left-[50%] translate-x-[-50%]"
              />
              <h2 className="text-[30px] text-center pt-24 mb-8">
                Fast Delivery
              </h2>
              <p className="text-[22px] font-light">
                Waiting to submit a job application can mean someone else
                pipping you to the post. That's why we provide fast delivery of
                all CV translations, so that you can keep up with the
                competition and pursue your career goals without delay.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SecondCvTranslationServices;
